import { Button, Divider, FlexboxGrid, Placeholder } from "rsuite";
import {
  Col24,
  DateUtils,
  GenderEnumHandler,
  GeneralUtils,
  PaymentMethodEnumHandler,
  PaymentStatusEnumHandler,
  Property,
  RegistrationInterface,
  Spacer,
} from "../../shared";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface RegistrationSearchDetailsProps {
  registration?: RegistrationInterface;
  onCancel: (registration: RegistrationInterface) => void;
}

export function RegistrationSearchDetails(
  props: RegistrationSearchDetailsProps
) {
  const { onCancel, registration } = props;

  if (!registration) {
    return <Placeholder.Paragraph rows={3} active />;
  }

  return (
    <FlexboxGrid justify="space-between" align="top">
      <Property label="Nome" title={registration.name} colSize={12} />
      <Property label="E-mail" title={registration.email} colSize={12} />
      <Property
        label="Idade"
        title={DateUtils.calculateAge(registration.birthdate) + " anos"}
        colSize={12}
      />
      <Property
        label="Sexo"
        title={GenderEnumHandler.label(registration.gender)}
        colSize={12}
      />
      <Property label="Telefone" title={registration.phone} colSize={6} />
      <Property
        label="Chave"
        title={registration.registrationKeyValue}
        colSize={12}
      />
      <Divider />
      <Property
        label="Associacao"
        title={registration.associationName}
        colSize={12}
      />
      <Property
        label="Loteamento"
        title={registration.lotDescription}
        colSize={12}
      />
      <Divider />
      <Property label="Evento" title={registration.eventName} colSize={12} />
      <Property
        label="Data"
        title={
          DateUtils.formatDate(registration.eventStartDate) +
          " - " +
          DateUtils.formatDate(registration.eventEndDate)
        }
        colSize={12}
      />
      <Property
        label="Hora"
        title={
          DateUtils.formatTime(registration.eventStartTime) +
          " às " +
          DateUtils.formatTime(registration.eventEndTime)
        }
        colSize={12}
      />
      <Property label="Cidade" title={registration.cityName} colSize={12} />
      <Property
        label="Valor"
        title={GeneralUtils.getAmountLabel(registration.totalPrice)}
        colSize={12}
      />
      <Property
        label="Desconto"
        title={GeneralUtils.getAmountLabel(registration.totalDiscount)}
        colSize={12}
      />
      <Property
        label="Métodos de Pagamento"
        title={PaymentMethodEnumHandler.label(registration.paymentMethod)}
        colSize={12}
      />
      <Property
        label={`${
          registration.cancelledAt ? "Último " : ""
        }Status de Pagamento`}
        title={PaymentStatusEnumHandler.label(registration.paymentStatus)}
        colSize={12}
      />
      {registration.cancelledAt ? (
        <Property
          label="Cancelado em:"
          title={DateUtils.localTime(
            registration.cancelledAt.toISOString(),
            true
          )}
          colSize={12}
        />
      ) : (
        <>
          <Spacer spacing={16} />
          <Col24>
            <Button
              color="red"
              appearance="primary"
              startIcon={<FontAwesomeIcon icon={faX} />}
              onClick={() => onCancel(registration)}
            >
              Cancelar Inscrição
            </Button>
          </Col24>
        </>
      )}
    </FlexboxGrid>
  );
}
