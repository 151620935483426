import { DateUtils, VoucherInterface, Property, GeneralUtils, DiscountTypeEnum } from "../../../shared";
import { FlexboxGrid, Placeholder } from "rsuite";

interface VoucherDetailsProps {
  voucher?: VoucherInterface;
}

export function VoucherDetails(props: VoucherDetailsProps) {
  const { voucher } = props;

  if (!voucher) {
    return <Placeholder.Paragraph rows={3} active />;
  }

  return (
    <>
      <FlexboxGrid justify="space-between" align="top">
        <Property label="Nome do voucher:" title={voucher.name} colSize={24} />
        <Property
          label="Inícia em:"
          title={DateUtils.localTime(voucher.activeAt.toISOString(), false)}
          colSize={12}
        />
        <Property
          label="Termina em"
          title={voucher.expiresAt ? DateUtils.localTime(voucher.expiresAt.toISOString(), false) : "N/I"}
          colSize={12}
        />
        {voucher.discountType === DiscountTypeEnum.Percentage ? (
          <Property label="Porcentagem de desconto" title={`${voucher.discountAmount}%`} colSize={12} />
        ) : (
          <Property
            label="Valor de desconto"
            title={GeneralUtils.getAmountLabel(voucher.discountAmount)}
            colSize={12}
          />
        )}
        <Property label="Status" title={voucher.isActive ? "Ativo" : "Inativo"} colSize={12} />
        <Property label="Número máximo de conversões" title={voucher.maxRedemptions} colSize={12} />
        <Property label="Vouchers utilizados" title={voucher.numberOfRedemptions} colSize={12} />
      </FlexboxGrid>
    </>
  );
}
